import React from "react";

export default function HeroTitle() {
  return (
    <div className="HeroContainer mt-6">
      <div className="HeroContent">
        <small>junior</small>
        <h2 >Front-end</h2>
        <h1>Developer</h1>
      </div>
    </div> 
  );
}